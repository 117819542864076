import { useState } from "react";
import usePartySocket from "partysocket/react";

export default function Counter({ room }: { room: string }) {
  const [count, setCount] = useState<number | null>(null);

  const socket = usePartySocket({
    room: room,
    onMessage(evt) {
      setCount(parseInt(evt.data));
    },
  });

  const increment = () => {
    socket.send("increment");
  };

  const styles = {
    backgroundColor: "#ff0f0f",
    borderRadius: "9999px",
    border: "none",
    color: "white",
    fontSize: "0.95rem",
    cursor: "pointer",
    padding: "1rem 3rem",
    margin: "1rem 0rem",
  };

  return (
    <button style={styles} onClick={increment}>
      Increment me! {count !== null && <>Count: {count}</>}
    </button>
  );
}
